import filtrooApi from "./filtrooApi";

export const createLink = (file: File[], type: string, url: string) => {
  const formData = new FormData();
  formData.append("imageFileAtt", file[0]);
  formData.append("attName", type);
  formData.append("attUrl", url);
  return filtrooApi.post("/company/addatt", formData);
};

export const deleteLink = (id: string, actualImageUrl: string) =>
  filtrooApi.delete(
    `/company/deleteatt/${id}?actualImageUrl=${actualImageUrl}`
  );
