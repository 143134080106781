import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "../contexts/LocalizeContext";
import * as moment from "moment";
import "../styles/pages/statistics.scss";
import Bar from "../base/Bar.base";
import { getStats } from "../api/statistics.api";

export default function StatisticsScreen() {
  const {
    APP: { STATISTICS },
  } = useTranslation();

  const [state, setState] = useState<{ claimed: any; weekly: any }>({
    claimed: {
      total: 0,
      lastItems: [],
    },
    weekly: [],
  });

  useEffect(() => {
    getStats()
      .then(({ data }) => {
        setState(data);
      })
      .catch((err) => console.error(err));
  }, []);

  const renderLastItems = useMemo(
    () =>
      state.claimed.lastItems.map((i: any) => (
        <div className="last-items">
          <span className="last-items-l">{i.name}</span>
          <span className="last-items-r">{i.quantity}</span>
        </div>
      )),
    [state.claimed.lastItems]
  );

  const renderWeeklyChart = useMemo(() => {
    let max = 0;
    state.weekly.forEach((i: any) => (i.value > max ? (max = i.value) : null));
    return (
      <div className="statistics-container-r-b">
        {state.weekly.map((i: any) => (
          <Bar name={i.day} maxValue={max} value={i.value} />
        ))}
      </div>
    );
  }, [state]);

  return (
    <div className="statistics-container">
      <div className="statistics-container-l">
        <div className="statistics-container-l-t">
          <h1>{STATISTICS.TITLE}</h1>
          <p>{STATISTICS.DESCRIPTION}</p>
        </div>
        <div className="statistics-container-l-card">
          <span className="statistics-container-l-card__date">
            {moment.default().format("MMMM DD")}
          </span>
          <div className="statistics-container-l-card__core">
            <h2>{state.claimed.total}</h2>
            <h3>{STATISTICS.CARDS.CLAIMED.TITLE}</h3>
          </div>
          <div style={{ width: "100%" }}>{renderLastItems}</div>
        </div>
      </div>

      <div className="statistics-container-r">
        <div className="statistics-container-r-t">
          <h3>{STATISTICS.CARDS.WEEKLY.TITLE}</h3>
          <span> {STATISTICS.CARDS.WEEKLY.LAST_WEEK}</span>
        </div>
        {renderWeeklyChart}
      </div>
    </div>
  );
}
