import React, { useEffect, useState } from "react";
import Button from "../base/Button.base";
import Dragger from "../base/Dragger.base";
import Input from "../base/Input.base";
import { useTranslation } from "../contexts/LocalizeContext";
import { useForm } from "../hooks/useForm";
import "../styles/pages/adminCategories.scss";
import { X, Gift } from "react-feather";
import Categories from "../components/Admin/Categories";
import { getCategories } from "../api/onBoarding.api";
import { newCategory } from "../api/admin.api";

export default function AdminCategoriesScreen() {
  const [loading, setLoading] = useState(true);
  const [isUploadingCategory, setIsUploadingCategory] = useState(false);
  const [categories, setCategories] = useState<any>([]);
  const {
    APP: { ADMIN },
  } = useTranslation();

  useEffect(() => {
    getCategories()
      .then(({ data: categoriesResponse }) => {
        setCategories(categoriesResponse);
        setLoading(false);
        setIsUploadingCategory(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { name, onChange, form } = useForm({
    name: "",
  });

  const [imageFileFree, setImageFileFree] = useState<File[] | null>(null);
  const [imageFileGold, setImageFileGold] = useState<File[] | null>(null);
  const [imageUrlFree, setImageUrlFree] = useState<string>("");
  const [imageUrlGold, setImageUrlGold] = useState<string>("");

  const submitNewCategory = async () => {
    try {
      setIsUploadingCategory(true);
      if (!name) {
        setIsUploadingCategory(false);
        return setErrorMessage("Empty field.");
      }
      if (!imageFileFree || !imageFileGold) {
        setIsUploadingCategory(false);
        return setErrorMessage("Image file empty.");
      }
      if (
        imageFileFree![0].size > 1500000 ||
        imageFileGold![0].size > 1500000
      ) {
        setIsUploadingCategory(false);
        return setErrorMessage("Max image size 1.5MB");
      }
      if (
        imageFileFree![0].type === "image/jpeg" ||
        "image/png" ||
        imageFileGold![0].type === "image/jpeg" ||
        "image/png"
      ) {
        console.log("success");
      }
      // submit new Category
      // const companyId = get(STORE.USER).companyId;
      const category = await newCategory(name, imageFileFree, imageFileGold);
      onChange("", "name");
      setImageFileFree(null);
      setImageFileGold(null);
      setImageUrlFree("");
      setImageUrlGold("");
      setIsUploadingCategory(false);
      setCategories((prev: any) => [...prev, category]);
    } catch {
      setIsUploadingCategory(false);
    }
  };

  return (
    <div className="admin-categories-container">
      <Categories
        onEdit={(id: string, category: any) => {
          const arr = [...categories];
          const categoriesArr = arr.map((i) => {
            if (i._id === id) {
              return category;
            }
            return i;
          });
          console.log("categorie: ", category);
          setCategories(categoriesArr);
        }}
        onDelete={(id: string) => {
          const arr = [...categories];
          const categoriesArr = arr.filter((i) => i._id !== id);
          setCategories(categoriesArr);
        }}
        cards={categories}
      />
      <form>
        <Gift
          className="admin-categories-form-icon"
          size={110}
          onClick={() => setErrorMessage(null)}
        />
        <h1>{ADMIN.CATEGORIES.TITLE}</h1>
        <p>{ADMIN.CATEGORIES.PARAGRAPH}</p>
        <Input
          name="name"
          value={name}
          maxLength={50}
          type="text"
          isFull
          placeholder={ADMIN.CATEGORIES.FORM.NAME}
          onChange={(value: any, name: any) => {
            onChange(value, name);
          }}
          style={{ marginTop: 15 }}
        />

        <div className="admin-categories-form-draggers">
          <div>
            <Dragger
              defaultImage={imageUrlFree}
              style={{}}
              onChange={(file: any, url: string) => {
                setImageFileFree(file);
                setImageUrlFree(url);
              }}
            />
            <p style={{ textAlign: "center" }}>
              {ADMIN.CATEGORIES.FORM.IMAGEFREE}
            </p>
          </div>
          <div>
            <Dragger
              defaultImage={imageUrlGold}
              style={{}}
              onChange={(file: any, url: string) => {
                setImageFileGold(file);
                setImageUrlGold(url);
              }}
            />
            <p style={{ textAlign: "center" }}>
              {ADMIN.CATEGORIES.FORM.IMAGEGOLD}
            </p>
          </div>
        </div>

        {errorMessage && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
          >
            <span>{errorMessage}</span>
            <X
              style={{ color: "red", marginLeft: 5 }}
              size={18}
              onClick={() => setErrorMessage(null)}
            />
          </div>
        )}

        <Button
          onClick={submitNewCategory}
          isPrincipal
          style={{ marginBottom: 30 }}
          value={ADMIN.CATEGORIES.FORM.CREATE}
          loading={isUploadingCategory ? true : false}
        />
      </form>
    </div>
  );
}
