import React, { useEffect, useState } from "react";
import Button from "../../base/Button.base";
import GoBack from "../../base/GoBack.base";
import { motion } from "framer-motion";
import { useTranslation } from "../../contexts/LocalizeContext";
import "../../styles/pages/onBoarding.scss";

export default function Publish(props: {
  onSubmit: any;
  onBack: any;
  step: any;
  loading: boolean;
}) {
  const {
    APP: { ON_BOARDING },
  } = useTranslation();

  return (
    <div className="on-boarding__publish">
      <motion.div
        animate={{
          x: 0,
          y: 0,
          scale: 1,
          rotate: 0,
        }}
        initial={{ y: 1000 }}
        transition={{
          delay: 0,
          duration: 0.35,
        }}
      >
        <div className="on-boarding__publish-buttons">
          <GoBack onClick={props.onBack} />
          <Button
            loading={props.loading}
            isPrincipal
            value={ON_BOARDING.PUBLISH}
            style={{ backgroundColor: "#FFF" }}
            onClick={() => props.onSubmit()}
          />
        </div>
      </motion.div>
    </div>
  );
}
