import React, { useRef, useState } from "react";

import Button from "../base/Button.base";
import Dragger from "../base/Dragger.base";
import Input from "../base/Input.base";
import Modal from "../components/Modal";
import ChangePassword from "../components/onBoarding/ChangePassword";
import { STORE } from "../constants/store.constants";
import { useStore } from "../contexts/StoreContext";
import "../styles/pages/profile.scss";

import FiltrooLogo from "../assets/Azul_Negro.png";

export default function AdminProfileScreen() {
  const { get } = useStore();
  const modalRef = useRef<any>();
  const [state, setState] = useState(get(STORE.USER));

  const updateData = (value: any, name: string) =>
    setState((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  const [changePassword, setChangePassword] = useState({
    password: "",
    rePassword: "",
    currentPassword: "",
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="profile__banner">
          <div
            className="profile__banner-dragger"
            style={{
              padding: "24px",
            }}
          >
            <img src={FiltrooLogo} alt="FiltrooLogo" />
          </div>
        </div>

        <div
          style={{
            width: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Input
            disabled
            isFull
            name="email"
            type="text"
            value={state.email}
            placeholder="Email"
            style={{ marginTop: 30 }}
            onChange={updateData}
          />

          <Modal title="Change password" ref={modalRef}>
            <ChangePassword
              buttonValue="Change password"
              onSubmit={() => {
                modalRef.current.close();
              }}
              state={changePassword}
              setState={setChangePassword}
            />
          </Modal>
          <Button
            isPrincipal
            style={{ marginTop: 35, marginBottom: 15 }}
            value="Change password"
            onClick={() => modalRef.current.open()}
          />
        </div>
      </div>
    </div>
  );
}
