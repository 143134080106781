import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { getRestaurantData } from "../api/home.api";
import { STORE } from "../constants/store.constants";
import { useLoader } from "../contexts/LoaderContext";
import { useTranslation } from "../contexts/LocalizeContext";
import { RewardsResponse } from "../interfaces/appInterfaces";
import {
  updateLinkImage,
  updateLinkType,
  updateLinkUrl,
} from "../api/profile.api";
import { useStore } from "../contexts/StoreContext";
import LinkCard from "./cards/Link.card";
import { deleteLink } from "../api/links.api";
import { getLinkNames } from "../api/onBoarding.api";

const LinksList = forwardRef((props, ref) => {
  const {
    APP: {
      HOME: { REWARDS, LINKS },
    },
  } = useTranslation();
  const { setLoading, loading } = useLoader();
  const { get } = useStore();
  const [state, setState] = useState({
    name: "",
    logo: "",
    image: "",
    file: null,
    description: "",
    attachments: [],
  });
  const [prev, setPrev] = useState({
    name: "",
    logo: "",
    image: "",
    description: "",
    file: null,
    attachments: [],
  });

  const {
    APP: { PROFILE },
  } = useTranslation();

  useEffect(() => {
    const user = get(STORE.USER);
    getRestaurantData(user?.companyId)
      .then(({ data }) => {
        console.log(data[0]);
        setState(data[0]);
        setPrev(data[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  const [linkNames, setLinkNames] = useState([{ _id: "", name: "" }]);
  useEffect(() => {
    getLinkNames().then((linkNames) => {
      console.log("linknames", linkNames.data);
      setLinkNames(linkNames.data);
      // setState((prevState) => ({ ...prevState, type: linkNames.data[0].name }));
    });
  }, []);

  useImperativeHandle(ref, () => ({
    addLink(link: any) {
      setState((prev: any) => ({
        ...prev,
        attachments: [...prev.attachments, link],
      }));
    },
  }));

  return (
    <div className="reward-container">
      <h2>{LINKS.TITLE}</h2>
      <div className="reward-container__cards">
        {state.attachments.map((i: any, index: number) => (
          <LinkCard
            linkNames={linkNames}
            withEdit
            key={index}
            onDelete={() => {
              deleteLink(i._id, i.attImage)
                .then(() => {
                  const arr = [...state.attachments];
                  const attachments = arr.filter(
                    (item: any) => item._id !== i._id
                  );
                  setState((prev) => ({
                    ...prev,
                    attachments,
                  }));
                  setLoading(false);
                })
                .catch((err) => {
                  console.error(err);
                  setLoading(false);
                });
            }}
            onEdit={async (_state) => {
              try {
                setLoading(true);
                const promises = [];
                let image = i?.attImage;
                if (_state?.file) {
                  image = await (
                    await updateLinkImage(index + 1, i.attImage, _state.file)
                  ).data.newImageUrl;
                }
                if (_state.type !== i?.attName || _state.type !== "") {
                  promises.push(updateLinkType(index + 1, _state.type));
                }
                if (_state.link !== i?.attUrl) {
                  promises.push(updateLinkUrl(index + 1, _state.link));
                }
                Promise.all(promises)
                  .then(() => {
                    setLoading(false);
                    const arr = [...state.attachments];
                    const attachments = arr.map((i: any, _index: number) => {
                      if (_index === index) {
                        return {
                          ...i,
                          attName: _state.type,
                          attUrl: _state.link,
                          attImage: image,
                        };
                      }
                      return i;
                    });

                    setState((prev: any) => ({
                      ...prev,
                      attachments,
                    }));
                  })
                  .catch((err) => {
                    setLoading(false);
                    console.error(err);
                  });
              } catch (err) {
                console.error(err);
                setLoading(false);
              }
            }}
            type={i?.attName}
            link={i?.attUrl}
            image={i?.attImage}
          />
        ))}
      </div>
    </div>
  );
});
export default LinksList;
