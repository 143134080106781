import React, { useEffect, useRef, useState } from "react";
import { Edit2 } from "react-feather";
import { getRestaurantData } from "../api/home.api";
import { getCategories } from "../api/onBoarding.api";
import {
  updateLinkImage,
  updateLinkType,
  updateLinkUrl,
  updateRestaurantCategory,
  updateRestaurantDescription,
  updateRestaurantImage,
  updateRestaurantName,
} from "../api/profile.api";
import Button from "../base/Button.base";
import Dragger from "../base/Dragger.base";
import Input, { IOptions } from "../base/Input.base";
import LinkCard from "../components/cards/Link.card";
import Modal from "../components/Modal";
import ChangePassword from "../components/onBoarding/ChangePassword";
import { STORE } from "../constants/store.constants";
import { useLoader } from "../contexts/LoaderContext";
import { useTranslation } from "../contexts/LocalizeContext";
import { useStore } from "../contexts/StoreContext";
import "../styles/pages/profile.scss";

export default function ProfileScreen() {
  const {
    APP: { ON_BOARDING },
  } = useTranslation();
  const { get } = useStore();
  const { setLoading } = useLoader();
  const modalRef = useRef<any>();
  const [state, setState] = useState({
    name: "",
    logo: "",
    image: "",
    file: null,
    description: "",
    attachments: [],
    category: "",
  });
  const [prev, setPrev] = useState({
    name: "",
    logo: "",
    image: "",
    description: "",
    file: null,
    attachments: [],
    category: "",
  });
  const {
    APP: { PROFILE },
  } = useTranslation();

  useEffect(() => {
    const user = get(STORE.USER);
    getRestaurantData(user?.companyId)
      .then(({ data }) => {
        console.log(data[0]);
        setState(data[0]);
        setPrev(data[0]);
      })
      .catch((err) => console.error(err));
  }, []);

  const updateData = (value: any, name: string) =>
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  const [changePassword, setChangePassword] = useState({
    password: "",
    rePassword: "",
    currentPassword: "",
  });

  const [categories, setCategories] = useState<any>(null);

  useEffect(() => {
    if (!categories) {
      getCategories().then((res) => {
        setCategories(res.data);
        // setState((prevState: any) => ({
        //   ...prevState,
        //   category: res.data[0].category,
        // }));
        // setPrev((prevState: any) => ({
        //   ...prevState,
        //   category: res.data[0].category,
        // }));
      });
    }
  }, []);

  useEffect(() => {
    console.log("state category: ", state.category);
  }, [state]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="profile__banner">
          <div className="profile__banner-dragger">
            <Dragger
              defaultImage={state.image}
              onChange={(file: File, url: string) => {
                updateData(url, "image");
                updateData(file, "file");
              }}
              style={{
                width: 150,
                height: 150,
                borderRadius: 22,
                backgroundColor: "#f5f5f5",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        <div
          style={{
            width: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Input
            isFull
            name="name"
            type="text"
            value={state.name}
            placeholder="Name"
            style={{ marginTop: 30 }}
            onChange={updateData}
          />
          <select
            placeholder={ON_BOARDING._2.CATEGORY}
            defaultValue={state.category}
            onChange={(v) => updateData(v.target.value, "category")}
            className={`input-container--full`}
            style={{ marginTop: 15 }}
            value={state.category}
          >
            {categories &&
              categories.map((option: IOptions) => (
                <option key={`${option._id}`} value={option._id}>
                  {option.name}
                </option>
              ))}
          </select>
          {/* <Input
            placeholder={ON_BOARDING._2.CATEGORY}
            name="category"
            isSelector
            isFull
            value={state.category}
            style={{ marginTop: 15 }}
            onChange={updateData}
            options={categories}
          /> */}
          <Input
            isFull
            isArea
            value={state.description}
            name="description"
            type="text"
            style={{ marginTop: 15 }}
            placeholder="Description"
            onChange={updateData}
          />

          <Button
            isPrincipal
            style={{ marginTop: 35, marginBottom: 15 }}
            onClick={async () => {
              console.log("click");
              try {
                setLoading(true);
                if (state?.file) {
                  await updateRestaurantImage(prev.image, state.file);
                  setState((prev) => ({
                    ...prev,
                    file: null,
                  }));
                }
                Promise.all([
                  updateRestaurantName(state.name),
                  updateRestaurantDescription(state.description),
                  updateRestaurantCategory(state.category),
                ]).finally(() => setLoading(false));
              } catch (err) {
                setLoading(false);
                console.error(err);
              }
            }}
            value={PROFILE.UPDATE}
          />
          <Modal title="Change password" ref={modalRef}>
            <ChangePassword
              buttonValue="Change password"
              onSubmit={() => {
                modalRef.current.close();
              }}
              state={changePassword}
              setState={setChangePassword}
            />
          </Modal>
          <Button
            style={{ marginBottom: 35 }}
            value="Change password"
            onClick={() => modalRef.current.open()}
          />
        </div>
      </div>
    </div>
  );
}
