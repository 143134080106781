import React from "react";

import { Edit2 } from "react-feather";

export default function PreviewCard(props: {
  onEdit: any;
  title: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div style={props.style} className="on-boarding-preview__item">
      <div className="on-boarding-preview__item__header">
        <h3>{props.title}</h3>
        <button onClick={props.onEdit}>
          <Edit2 style={{ color: "#6F6F6F" }} />
        </button>
      </div>
      <div>{props.children}</div>
    </div>
  );
}
