import React, { createContext, useContext, useState } from "react";
import store from "store";

type context = {
  set: (key: string, value: any) => void;
  get: (key: string) => any;
  clear: () => void;
};
const StoreContext = createContext({} as context);

export const StoreProvider = (props: { children: React.ReactNode }) => {
  const [global, setGlobal] = useState<any>({});

  const set = (key: string, value: any) => {
    store.set(key, value);
    setGlobal((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const get = (key: string) => {
    const v = global[key];
    if (!v) {
      return store.get(key, null);
    }
    return v;
  };

  const clear = () => setGlobal({});

  const context = {
    set,
    get,
    clear,
  };

  return (
    <StoreContext.Provider value={context}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
