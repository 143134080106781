import { Select } from "antd";
import React, { ReactNode } from "react";
import "../styles/base/input.scss";

export interface IOptions {
  _id: string;
  name: string;
  image: string;
}

export interface LinkNameOptions {
  _id: string;
  name: string;
}

export default function Input(props: {
  placeholder: string;
  onChange: any;
  name: string;
  disabled?: boolean;
  value?: string | number | readonly string[] | undefined;
  className?: string;
  style?: React.CSSProperties;
  isFull?: boolean;
  maxLength?: number;
  isSelector?: boolean;
  options?: IOptions[];
  linkNameOptions?: LinkNameOptions[];
  isArea?: boolean;
  type?: React.HTMLInputTypeAttribute | undefined;
  min?: number;
}) {
  if (props.isArea) {
    return (
      <textarea
        disabled={props?.disabled}
        maxLength={100}
        placeholder={props.placeholder}
        style={props?.style}
        value={props?.value}
        onChange={(v) => props.onChange(v.target.value, props.name)}
        className={`input-container--full input-area ${
          props.className ? props.className : ""
        }`}
      />
    );
  } else if (props.isSelector) {
    return (
      <select
        disabled={props?.disabled}
        placeholder={props.placeholder}
        style={props?.style}
        // defaultValue={props?.value}
        onChange={(v) => props.onChange(v.target.value, props.name)}
        className={`input-container--full ${
          props.className ? props.className : ""
        }`}
        value={props?.value}
      >
        {/* <option defaultValue="">Select</option> */}

        {props.options &&
          props.options?.map((option: IOptions) => (
            <option key={`${option._id}`} value={option._id}>
              {option.name}
            </option>
          ))}

        {props.linkNameOptions &&
          props.linkNameOptions?.map((option: LinkNameOptions) => (
            <option key={`${option._id}`} value={option.name}>
              {option.name}
            </option>
          ))}
      </select>
    );
  }
  return (
    <input
      className={`input-container${props.isFull ? "--full" : ""} ${
        props.className ? props.className : ""
      }`}
      maxLength={props.maxLength}
      disabled={props?.disabled}
      type={props.type}
      min={props?.min}
      style={props.style}
      onChange={(v) => props.onChange(v.target.value, props.name)}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
}
