import { stat } from "fs";
import React, { useState, useEffect } from "react";
import Button from "../../base/Button.base";
import Dragger from "../../base/Dragger.base";
import GoBack from "../../base/GoBack.base";
import Input from "../../base/Input.base";
import { useTranslation } from "../../contexts/LocalizeContext";
import "../../styles/pages/onBoarding.scss";
import { getLinkNames } from "../../api/onBoarding.api";

export default function Links(props: {
  onSubmit: any;
  onAdd: any;
  onBack: any;
  links: Array<any>;
}) {
  const {
    APP: { ON_BOARDING, HOME },
  } = useTranslation();

  const [linkNames, setLinkNames] = useState<any>([{ _id: "", name: "" }]);
  const [state, setState] = useState({
    image: "",
    file: "",
    type: "",
    link: "",
  });

  const updateState = (value: string, name: string) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  useEffect(() => {
    getLinkNames().then((linkNames) => {
      console.log("linknames", linkNames.data);
      setLinkNames(linkNames.data);
      setState((prevState) => ({ ...prevState, type: linkNames.data[0].name }));
    });
  }, []);

  return (
    <form className="on-boarding-children">
      <Dragger
        disabled={props.links.length >= 4}
        defaultImage={state?.image}
        style={{ marginTop: 30 }}
        onChange={(file: any, url: string) => {
          setState((prevState) => ({
            ...prevState,
            image: url,
            file,
          }));
        }}
      />
      <Input
        disabled={props.links.length >= 4}
        isSelector
        linkNameOptions={linkNames}
        name="type"
        type="text"
        isFull
        value={state.type}
        placeholder={HOME.LINKS.TYPE}
        onChange={updateState}
        style={{ marginTop: 15 }}
      />
      <Input
        disabled={props.links.length >= 4}
        name="link"
        type="text"
        isFull
        value={state.link}
        placeholder={HOME.LINKS.LINK}
        onChange={updateState}
        style={{ marginTop: 15 }}
      />
      <div
        className="on-boarding__buttons"
        style={{ justifyContent: "flex-start", marginLeft: 0 }}
      >
        <Button
          isPrincipal
          disabled={
            !state.file || !state.link || !state.type || props.links.length >= 4
          }
          value={ON_BOARDING.ADD}
          className="on-boarding__buttons-add"
          style={{ backgroundColor: "#FFF" }}
          onClick={() => {
            props.onAdd(state);
            setState({
              image: "",
              file: "",
              link: "",
              type: linkNames[0].name,
            });
          }}
        />
      </div>
      <div
        className="on-boarding__card"
        style={{ marginLeft: -30, marginTop: 50 }}
      >
        <div className="on-boarding__buttons" style={{ marginTop: 0 }}>
          <GoBack onClick={props.onBack} />
          <Button
            isPrincipal
            value={ON_BOARDING.CONTINUE}
            style={{ backgroundColor: "#FFF" }}
            onClick={() => props.onSubmit(state)}
          />
        </div>
      </div>
    </form>
  );
}
