import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "../contexts/LocalizeContext";
import * as moment from "moment";
import "../styles/pages/userStatistics.scss";
import Bar from "../base/Bar.base";
import { getStats } from "../api/statistics.api";
import Chart from "react-apexcharts";


export default function UserStatisticsScreen() {
  const {
    APP: { USER_STATISTICS },
  } = useTranslation();

  const [userAgeState, setUserAgeState] = useState<{ options: any; series: any }>({
    options: {
      chart: {
        id: "basic-bar"
      },
      xaxis: {
        categories: ['-20', '20-30', '30-40', '+40']
      }
    },
    series: [
      {
        name: "series-1",
        data: [1500, 2000, 1000, 800]
      }
    ]
  });

  const [userGenereState, setUserGenereState] = useState<{ options: any}>({
    options: {
      series: [40, 60],
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [USER_STATISTICS.GRAPHICS.USERS_GENERE.MALE, USER_STATISTICS.GRAPHICS.USERS_GENERE.FEMALE],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  });

  const [modeSummaryState, setModeSummaryState] = useState<{options: any}>({
    options: {
      series: [70, 30],
      chart: {
      type: 'donut',
      },
      dataLabels: {
        enabled: false,
      },
      labels: [USER_STATISTICS.GRAPHICS.MODE_SUMMARY.REWARDS, USER_STATISTICS.GRAPHICS.MODE_SUMMARY.PRIVACY],
      colors:['#F7DA69', '#E91E63'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  });

  useEffect(() => {
    getStats()
      .then(({ data }) => {
        setUserAgeState(data);
      })
      .catch((err) => console.error(err));
  }, []);


  return (

    <div className="user-statistics-container">
      <div className="user-statistics-container__title">
        <h1>{USER_STATISTICS.TITLE}</h1>
        <p>{USER_STATISTICS.DESCRIPTION}</p>
      </div>
      <div className="user-statistics-container__subtitle">
        <h1>{USER_STATISTICS.PARAGRAPH}</h1>
      </div>
      <div className="user-statistics-container__row">

        <div className="user-statistics-container__card">
          <h3 className="user-statistics-container__card-title">
            {USER_STATISTICS.GRAPHICS.USER_TIME.TITLE}
          </h3>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{ marginTop: '30px', color: '#26A0FC', fontSize: '50px', fontWeight: 'bold' }}>12</p>
          </div>
        </div>

        <div className="user-statistics-container__card">
          <h3 className="user-statistics-container__card-title">
            {USER_STATISTICS.GRAPHICS.USERS_AGE.TITLE}
          </h3>
          <div className="user-statistics-container__card-body">
            <Chart
              options={userAgeState.options}
              series={userAgeState.series}
              type="bar"
              width="100%"
            />
          </div>
        </div>

        <div className="user-statistics-container__card">
          <h3 className="user-statistics-container__card-title">
            {USER_STATISTICS.GRAPHICS.USERS_GENERE.TITLE}
          </h3>
          <div className="user-statistics-container__card-body">
            <Chart
              options={userGenereState.options}
              type={'pie'}
              series={userGenereState.options.series}
              chart={userGenereState.options.chart}
              labels={userGenereState.options.labels}
              responsive={userGenereState.options.responsive}
            />
          </div>
        </div>

      </div>
      <div className="user-statistics-container__row">

        <div className="user-statistics-container__card">
          <h3 className="user-statistics-container__card-title">
            {USER_STATISTICS.GRAPHICS.MODE_SUMMARY.TITLE}
          </h3>
          <div className="user-statistics-container__card-body">
            <Chart
              options={modeSummaryState.options}
              type="donut"
              series={modeSummaryState.options.series}
              chart={modeSummaryState.options.chart}
              dataLabels={modeSummaryState.options.dataLabels}
              responsive={modeSummaryState.options.responsive}
              colors={modeSummaryState.options.colors}
            />
          </div>
        </div>

        <div className="user-statistics-container__card big-card">
          <h3 className="user-statistics-container__card-title">
            {USER_STATISTICS.GRAPHICS.USERS_DISTRIBUTION.TITLE}
          </h3>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={require('../assets/localization.jpg')} alt="localization_image" style={{ width: '90%', marginBottom: '20px' }} />
          </div>
        </div>

      </div>
    </div>

  );
}
