import React, { useEffect, useRef, useState } from "react";
import { Edit2, X } from "react-feather";
import Button from "../../base/Button.base";
import Dragger from "../../base/Dragger.base";
import Input from "../../base/Input.base";
import { useTranslation } from "../../contexts/LocalizeContext";
import { RewardsResponse } from "../../interfaces/appInterfaces";
import "../../styles/components/reward.scss";
import Modal from "../Modal";

export default function RewardCard(
  props: Partial<RewardsResponse> & {
    showActive: boolean;
    withEdit?: boolean;
    onDelete?: () => void;
    onEdit?: (v: any) => any;
    key?: any;
  }
) {
  const {
    APP: { MODALS, HOME },
  } = useTranslation();
  const editModal = useRef<any>(null);
  const deleteModal = useRef<any>(null);
  const [state, setState] = useState({
    image: props.image,
    file: null,
    name: props.name,
    coinsPrice: props.coinsPrice,
  });
  const updateState = (value: string, name: any) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  return (
    <div key={props.key}>
      <Modal ref={editModal} title={MODALS.EDIT.REWARDS.TITLE}>
        <div>
          <Dragger
            defaultImage={state.image}
            onChange={(file: any, url: string) => {
              setState((prevState) => ({
                ...prevState,
                image: url,
                file,
              }));
            }}
          />
          <Input
            name="name"
            type="text"
            isFull
            maxLength={50}
            value={state.name}
            placeholder={HOME.REWARDS.FORM.NAME}
            onChange={updateState}
            style={{ marginTop: 15 }}
          />
          <div style={{ width: 200 }}>
            <Input
              name="coinsPrice"
              type="number"
              isFull
              value={state.coinsPrice}
              placeholder={HOME.REWARDS.FORM.COINS}
              onChange={updateState}
              style={{ marginTop: 15 }}
            />
          </div>
          <Button
            style={{ marginTop: 25 }}
            isPrincipal
            disabled={
              !state.coinsPrice || !state.image || !state.name || !state.image
            }
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(state);
                editModal.current.close();
              }
            }}
            value={MODALS.EDIT.UPDATE}
          />
        </div>
      </Modal>
      <Modal ref={deleteModal} title={MODALS.DELETE.REWARDS.TITLE}>
        <div>
          <span>{MODALS.DELETE.SURE}</span>
          <Button
            style={{ marginTop: 25 }}
            isPrincipal
            onClick={() => {
              if (props.onDelete) {
                props.onDelete();
                deleteModal.current.close();
              }
            }}
            value={MODALS.DELETE.DELETE}
          />
        </div>
      </Modal>
      <div className="reward-card-container">
        <div className="reward-card-container-l">
          <img className="reward-card-container-l" src={props?.image} />
        </div>
        <div className="reward-card-container-r">
          <p className="reward-card-container-r-t">{props?.name}</p>
          <div className="reward-card-container-r-b">
            <div className="reward-card-container-r-b__coins">
              <img src={require("../../assets/coin.png")} alt="coin" />
              <span>{props?.coinsPrice} coins</span>
            </div>
            {props.showActive && (
              <div className="reward-card-container-r-b__status">
                <div />
                <span>Active</span>
              </div>
            )}
          </div>
        </div>
        {props.withEdit && (
          <div className="reward-card-container-fab">
            <div className="reward-card-container-fab__holder">
              <button onClick={() => editModal.current.open()}>
                <Edit2 />
              </button>
              <button
                onClick={() => deleteModal.current.open()}
                className="reward-card-container-fab__holder--delete"
              >
                <X />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
