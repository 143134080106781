// const restaurantResponse = await filtrooApi.get<RestaurantResponse[]>(
//     `/restaurants`
//   );
//   const filterResponse = await filtrooApi.get<FilterResponse[]>(
//     `/filters/byrestaurant`
//   );
//   const rewardResponse = await filtrooApi.get<RewardsResponse[]>(
//     `/rewards/byrestaurant`
//   );

import filtrooApi from "./filtrooApi";

export const getRestaurantData = (id: string) =>
  filtrooApi.get(`/company/${id}`);

export const getUsers = (id?:string, direction?:string) =>{
  return filtrooApi.get(`/users${id ? `?userId=${id}&direction=${direction}` : ""}`)};

export const getUserTypeStatistics = () =>
  filtrooApi.get(`/users/userscount`);

export const getUserOsStatistics = () =>
  filtrooApi.get(`/users/oscount`);

export const getUserCountryStatistics = () =>
  filtrooApi.get(`/users/countrycount`);

export const newCategory = async (
    name: string,
    imageFileFree: File[],
    imageFileGold: File[]
  ) => {
    try {
      const formData = new FormData();
      formData.append("imageFileCategoryFree", imageFileFree[0]);
      formData.append("imageFileCategoryGold", imageFileGold[0]);
      formData.append("name", name);
      formData.append("active", "false");
      const res = await filtrooApi.post(`/category`, formData);
  
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const updateCategoryActive = async (
    id: string,
    active: string,
  ) => {
    try {
      const formData = new FormData();
      formData.append("active", active);
      const res = await filtrooApi.post(`/category/changeactive/${id}`, formData);
  
      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const deleteCategory = (id: string) =>
  filtrooApi.delete(`/category/${id}`);

export const updateCategoryName = (id: string, name: string) =>
  filtrooApi.post(`/category/changename/${id}`, { name });

export const updateCategoryImage = (
  id: string,
  actualUrlImage: string,
  imageFileCategory: File[],
  isFree: boolean,
) => {
  const formData = new FormData();
  formData.append("actualUrlImage", actualUrlImage);
  formData.append("imageFileCategory", imageFileCategory[0]);
  formData.append("isFree", String(isFree));
  
  return filtrooApi.post(`/category/changeimage/${id}`, formData);
};

export const existAnyCompanyByCategory = async (categoryId: string) => {
  const exist = filtrooApi.get(`/category/existanycompanybycategory/${categoryId}`);
  return exist;
}