// import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from "axios";
// import { useContext } from "react";

// import { getAsyncStorage } from "../helpers/asyncStorage";
// import { AuthContext } from "../contexts/authContext/AuthContext";

// const baseURL = "http://192.168.100.140:8080/backoffice"; //DEV HOME IP

// const baseURL = "http://192.168.1.56:8080/backoffice"; //DEV OFFICE IP

// const baseURL= 'https://filtroo.herokuapp.com/backoffice'; //REMOTE

const baseURL = "https://api.filtroo.co/backoffice";
const filtrooApi = axios.create({ baseURL });

export default filtrooApi;
