import filtrooApi from "./filtrooApi";

export const updateLinkType = (index: number, type: string) =>
  filtrooApi.post(`/company/changenameatt${index}`, { name: type });

export const updateLinkUrl = (index: number, url: string) =>
  filtrooApi.post(`/company/changeurlatt${index}`, { url });

export const updateLinkImage = (
  index: number,
  actualUrlImage: string,
  file: File[]
) => {
  const formData = new FormData();
  formData.append("actualUrlImage", actualUrlImage);
  formData.append("imageFileAtt1", file[0]);
  return filtrooApi.post(`/company/changeimageatt${index}`, formData);
};

export const updateRestaurantName = (name: string) =>{
  console.log("update name")
  filtrooApi.post("/company/changename", { name })};

export const updateRestaurantDescription = (description: string) =>
  filtrooApi.post("/company/changedescription", { description });

export const updateRestaurantImage = (actualUrlImage: string, file: File[]) => {
  const formData = new FormData();
  formData.append("actualUrlImage", actualUrlImage);
  formData.append("imageFileRestaurant", file[0]);
  return filtrooApi.post("/company/changerestaurantimage", formData);
};

export const updateRestaurantCategory = (category: string) => {
  filtrooApi.post("/company/changecategory", { category });
};
