import { useEffect, useRef, useState } from "react";
import { Edit2, X } from "react-feather";
import Button from "../../../base/Button.base";
import Dragger from "../../../base/Dragger.base";
import Input from "../../../base/Input.base";
import { useTranslation } from "../../../contexts/LocalizeContext";
import { CategoriesResponse } from "../../../interfaces/appInterfaces";
import "../../../styles/components/category.scss";
import Modal from "../../Modal";
import { Switch } from "antd";
import { existAnyCompanyByCategory } from "../../../api/admin.api";

export default function CategoryCard(
  props: Partial<CategoriesResponse> & {
    showActive: boolean;
    withEdit?: boolean;
    onDelete?: () => void;
    onEdit?: (v: any) => any;
    key?: any;
  }
) {
  const {
    APP: { MODALS, ADMIN },
  } = useTranslation();
  const editModal = useRef<any>(null);
  const deleteModal = useRef<any>(null);
  const [state, setState] = useState({
    imageFree: props.imageFree,
    imageGold: props.imageGold,
    fileFree: null,
    fileGold: null,
    name: props.name,
    active: props.active,
    removable: false,
  });

  const updateState = (value: any, name: any) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const handleSwitch = (value: boolean) =>
    setState((prevState) => ({
      ...prevState,
      active: value,
    }));

  const checkCompanyByCategory = () => {
    existAnyCompanyByCategory(props._id!)
      .then(({ data }) => {
        console.log("data: ", data);
        if (data.exist === false) {
          updateState(true, "removable");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div key={props._id}>
      <Modal ref={editModal} title={MODALS.EDIT.CATEGORY.TITLE}>
        <div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <div>
              <Dragger
                defaultImage={state.imageFree}
                onChange={(file: any, url: string) => {
                  setState((prevState) => ({
                    ...prevState,
                    imageFree: url,
                    fileFree: file,
                  }));
                }}
              />
              <p style={{ textAlign: "center" }}>
                {ADMIN.CATEGORIES.FORM.IMAGEFREE}
              </p>
            </div>
            <div>
              <Dragger
                defaultImage={state.imageGold}
                onChange={(file: any, url: string) => {
                  setState((prevState) => ({
                    ...prevState,
                    imageGold: url,
                    fileGold: file,
                  }));
                }}
              />
              <p style={{ textAlign: "center" }}>
                {ADMIN.CATEGORIES.FORM.IMAGEGOLD}
              </p>
            </div>
          </div>
          <Input
            name="name"
            type="text"
            isFull
            maxLength={50}
            value={state.name}
            placeholder={ADMIN.CATEGORIES.FORM.NAME}
            onChange={updateState}
            style={{ marginTop: 15 }}
          />
          <div style={{ width: 200 }}>
            <Switch
              className="edit-switch"
              checked={state.active}
              checkedChildren={ADMIN.CATEGORIES.FORM.ACTIVE}
              unCheckedChildren={ADMIN.CATEGORIES.FORM.DISABLED}
              onChange={handleSwitch}
              style={{ marginTop: 15 }}
            />
          </div>
          <Button
            style={{ marginTop: 25 }}
            isPrincipal
            disabled={
              state.imageFree === props.imageFree &&
              state.name === props.name &&
              state.imageGold === props.imageGold &&
              state.active === props.active
                ? true
                : false
            }
            onClick={() => {
              if (props.onEdit) {
                props.onEdit(state);
                editModal.current.close();
              }
            }}
            value={MODALS.EDIT.UPDATE}
          />
        </div>
      </Modal>
      <Modal ref={deleteModal} title={MODALS.DELETE.CATEGORY.TITLE}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!state.removable && (
            <span style={{ textAlign: "center" }}>
              There are profiles with this category. Reassign category of those
              profiles before deleting.{" "}
            </span>
          )}
          <span>{MODALS.DELETE.SURE}.</span>
          <Button
            style={{ marginTop: 25 }}
            isPrincipal
            onClick={() => {
              if (props.onDelete) {
                props.onDelete();
                deleteModal.current.close();
              }
            }}
            value={MODALS.DELETE.DELETE}
            disabled={!state.removable}
          />
        </div>
      </Modal>
      <div className="category-card-container">
        <div className="category-card-container-l-free">
          <img
            className="category-card-container-l-image"
            alt="iconCategory"
            src={props?.imageFree}
          />
        </div>
        <div className="category-card-container-l-gold">
          <img
            className="category-card-container-l-image"
            alt="iconCategory"
            src={props?.imageGold}
          />
        </div>
        <div className="category-card-container-r">
          <p className="category-card-container-r-t">{props?.name}</p>
          {props.showActive && (
            <div className="category-card-container-r-b__status">
              <div
                style={{ backgroundColor: props?.active ? undefined : "red" }}
              />
              <span>{props?.active ? "Active" : "Disabled"}</span>
            </div>
          )}
          <div className="category-card-container-r-b">
            <div className="category-card-container-r-b__views">
              {/* <span>{props?.link} views</span> 
              <span>10000 views</span> */}
            </div>
          </div>
        </div>
        {props.withEdit && (
          <div className="category-card-container-fab">
            <div className="category-card-container-fab__holder">
              <button onClick={() => editModal.current.open()}>
                <Edit2 style={{ color: "#6F6F6F" }} />
              </button>
              <button
                onClick={() => {
                  deleteModal.current.open();
                  checkCompanyByCategory();
                }}
                className="category-card-container-fab__holder--delete"
              >
                <X />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
