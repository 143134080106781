import React, { createContext, useContext, useState } from "react";
import es from "../locales/es-ES";
import en from "../locales/en-EN";

const LocalizeContext = createContext({ ...es, ...en });

const locales: any = {
  es,
  en,
};

export const LocalizeProvider = (props: { children: React.ReactNode }) => {
  const [selectedLocale, setSelectedLocale] = useState<string>("en");
  const { children } = props;
  const translations = {
    ...locales[selectedLocale],
  };

  return (
    <LocalizeContext.Provider value={translations}>
      {children}
    </LocalizeContext.Provider>
  );
};

export const useTranslation = () => useContext(LocalizeContext);
