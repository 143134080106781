import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { LoginScreen } from "../screens/LoginScreen";
import { AuthContext } from "../contexts/authContext/AuthContext";
import Layout from "../components/Layout";
import AdminLayout from "../components/Admin/AdminLayout";
import HomeScreen from "../screens/HomeScreen";
import AdminHomeScreen from "../screens/AdminHomeScreen";
import StatisticsScreen from "../screens/StatisticsScreen";
import OnBoarding from "../screens/OnBoarding";
import ProfileScreen from "../screens/ProfileScreen";
import { useStore } from "../contexts/StoreContext";
import LinkScreen from "../screens/LinkScreen";
import UserStatisticsScreen from "../screens/UserStatisticsScreen";
import AdminCategoriesScreen from "../screens/AdminCategoriesScreen";
import AdminProfileScreen from "../screens/AdminProfileScreen";

export const AppRouter = () => {
  const { get } = useStore();
  const { status, token } = useContext(AuthContext);
  // const auth = true;

  const navigate = useNavigate();

  useEffect(() => {
    console.log(status, token, get("USER"));
    if (status === "unauthorized" ) {
      return navigate("/", { replace: true });
    } else {
      const user = get("USER");
      if (user?.rol === "company") {
        if (user?.companyId ) {
          navigate("/home", { replace: true });
        } else {
          navigate("/on-boarding", { replace: true });
        }
      } else {
        navigate("/admin/users", { replace: true });
      }
    }
  }, [token, status]);

  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="on-boarding" element={<OnBoarding />} />
      <Route path="/*" element={<Layout />}>
        <Route path="home" element={<HomeScreen />} />
        <Route path="statistics" element={<StatisticsScreen />} />
        <Route path="profile" element={<ProfileScreen />} />
        <Route path="links" element={<LinkScreen />} />
        <Route path="userstatistics" element={<UserStatisticsScreen />} />
      </Route>
      <Route path="/admin/*" element={<AdminLayout />}>
        <Route path="users" element={<AdminHomeScreen />} />
        <Route path="categories" element={<AdminCategoriesScreen />} />
        <Route path="profile" element={<AdminProfileScreen />} />
      </Route>
    </Routes>
  );
};
