import React, { useMemo } from "react";
import {
  deleteCategory,
  updateCategoryActive,
  updateCategoryImage,
  updateCategoryName,
} from "../../api/admin.api";
import { useLoader } from "../../contexts/LoaderContext";
import { useTranslation } from "../../contexts/LocalizeContext";
import { CategoriesResponse } from "../../interfaces/appInterfaces";
import CategoryCard from "./cards/Category.card";

export default function Categories(props: {
  cards: Array<CategoriesResponse>;
  onDelete: (id: string) => void;
  onEdit: (id: string, payload: any) => void;
}) {
  const {
    APP: {
      ADMIN: { CATEGORIES },
    },
  } = useTranslation();
  const { setLoading, loading } = useLoader();

  const renderCategories = useMemo(
    () =>
      props.cards.map((i) => (
        <CategoryCard
          onDelete={() => {
            setLoading(true);
            deleteCategory(i._id)
              .then(() => {
                setLoading(false);
                props.onDelete(i._id);
              })
              .catch((err: any) => {
                setLoading(false);
                console.error(err);
              });
          }}
          onEdit={async (state) => {
            try {
              setLoading(true);
              let imageFree = i.imageFree;
              let imageGold = i.imageGold;
              const promises = [];
              if (i.name !== state.name) {
                promises.push(updateCategoryName(i._id, state.name));
              }
              if (i.active !== state.active) {
                promises.push(updateCategoryActive(i._id, state.active));
              }

              if (state.fileFree) {
                const response = await updateCategoryImage(
                  i._id,
                  i.imageFree,
                  state.fileFree,
                  true
                );
                console.log(response);
                imageFree = response.data.newImageUrlFree;
              }

              if (state.fileGold) {
                const response = await updateCategoryImage(
                  i._id,
                  i.imageGold,
                  state.fileGold,
                  false
                );
                console.log(response);
                imageGold = response.data.newImageUrlGold;
              }

              Promise.all(promises)
                .then((res) => {
                  console.log("RES; ", res);
                  setLoading(false);
                  props.onEdit(i._id, {
                    ...state,
                    imageFree,
                    imageGold,
                    _id: i._id,
                  });
                })
                .catch((err) => {
                  setLoading(false);
                  console.error(err);
                });
            } catch (err) {
              console.error(err);
            }
          }}
          withEdit
          showActive
          {...i}
          key={i._id}
        />
      )),
    [props.cards, loading]
  );

  return (
    <div className="category-container">
      <h2>{CATEGORIES.CARD}</h2>
      <div className="category-container__cards">{renderCategories}</div>
    </div>
  );
}
