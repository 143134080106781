import React, { useContext, useState } from "react";
import { Bell } from "react-feather";
import { motion } from "framer-motion";
import "../../styles/components/navbar.scss";
import { AuthContext } from "../../contexts/authContext/AuthContext";
import { Dropdown } from "antd";
import { useTranslation } from "../../contexts/LocalizeContext";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../contexts/LoaderContext";
import LoaderTop from "../LoaderTop";

export default function Navbar(props: {
  breadcrumb?: string;
  notifications?: Array<any>;
  image?: string;
  withDropdown?: boolean;
}) {
  const navigate = useNavigate();
  const { signOut } = useContext(AuthContext);
  const { image, breadcrumb } = props;
  const [visible, setVisible] = useState(false);
  const { loading } = useLoader();
  const { NAV } = useTranslation();

  return (
    <>
      <nav className="navbar-container">
        {loading && <LoaderTop />}
        <img
          alt="logo"
          className="navbar-container__logo"
          src={require("../../assets/Azul_Negro.png")}
        />
        <span className="navbar-container__breadcrumb">{breadcrumb}</span>
        <div className="navbar-container__r">
          <Bell style={{ color: "#6F6F6F" }} />

          <Dropdown
            trigger={["click"]}
            overlay={
              <div className="navbar-container__r__dropdown">
                {props.withDropdown && (
                  <button type="button" onClick={() => navigate("/admin/profile")}>
                    {NAV.PROFILE}
                  </button>
                )}
                <button type="button" onClick={signOut}>
                  {NAV.SIGN_OUT}
                </button>
              </div>
            }
          >
            <button
              type="button"
              className="navbar-container__r-button"
              style={{ marginLeft: 15 }}
              onClick={() => setVisible((prev) => !prev)}
            >
              <img
                className="navbar-container__r-user"
                alt='imageNavbar'
                style={{ marginLeft: 0 }}
                src={image || require("../../assets/account.png")}
              />
            </button>
          </Dropdown>
        </div>
      </nav>
    </>
  );
}
