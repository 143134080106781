import React, { useEffect, useRef, useState } from "react";
import { getRestaurantData } from "../api/home.api";
import { createLink } from "../api/links.api";
import { getLinkNames } from "../api/onBoarding.api";
import {
  updateLinkImage,
  updateLinkType,
  updateLinkUrl,
} from "../api/profile.api";
import Button from "../base/Button.base";
import Dragger from "../base/Dragger.base";
import Input from "../base/Input.base";
import LinkCard from "../components/cards/Link.card";
import LinksList from "../components/Links";
import { STORE } from "../constants/store.constants";
import { useLoader } from "../contexts/LoaderContext";
import { useTranslation } from "../contexts/LocalizeContext";
import { useStore } from "../contexts/StoreContext";

export default function LinkScreen() {
  const {
    APP: { HOME },
  } = useTranslation();
  const linksRef = useRef<any>();
  const { loading, setLoading } = useLoader();
  const [state, setState] = useState({
    type: "",
    link: "",
    image: "",
    file: [],
  });

  const updateState = (value: any, key: string) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const handleSubmit = () => {
    setLoading(true);
    createLink(state.file, state.type, state.link)
      .then(({ data }) => {
        console.log(data);
        linksRef.current.addLink(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("state", state);
  }, [state]);

  const [linkNames, setLinkNames] = useState([{ _id: "", name: "" }]);
  useEffect(() => {
    getLinkNames().then((linkNames) => {
      console.log("linknames", linkNames.data);
      setLinkNames(linkNames.data);
      setState((prevState) => ({ ...prevState, type: linkNames.data[0].name }));
    });
  }, []);

  return (
    <div className="home-container">
      <LinksList ref={linksRef} />
      <form>
        <h1>{HOME.LINKS.FORM.TITLE}</h1>
        {/* <p>{HOME.REWARDS.PARAGRAPH}</p> */}
        <Input
          name="type"
          value={state.type}
          isSelector
          type="text"
          isFull
          placeholder={HOME.LINKS.FORM.TYPE}
          onChange={updateState}
          style={{ marginTop: 15 }}
          linkNameOptions={linkNames}
        />
        <div style={{ width: 200 }}>
          <Input
            name="link"
            value={state.link}
            type="text"
            isFull
            placeholder={HOME.LINKS.FORM.URL}
            onChange={updateState}
            style={{ marginTop: 15 }}
          />
        </div>

        <Dragger
          defaultImage={state.image}
          style={{ marginTop: 15, marginBottom: 20 }}
          onChange={(file: any, url: string) => {
            updateState(file, "file");
            updateState(url, "image");
          }}
        />

        <Button
          loading={loading}
          disabled={!state.image || !state.link || !state.type}
          onClick={handleSubmit}
          isPrincipal
          style={{ marginBottom: 30 }}
          value={HOME.LINKS.FORM.CREATE}
        />
      </form>
    </div>
  );
}
